import React from 'react';

import { LabelMedium } from 'baseui/typography';

import { useStyletron } from 'theme';
import { LinkButton } from 'components/Button';

export default function Verify() {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: theme.sizing.scale600,
      })}
    >
      <LabelMedium>
        We sent you an email with a magic link that will verify you account.
        Click that link in your email to start using Scheduler.
      </LabelMedium>
      <LinkButton
        to="/"
        $style={{
          marginTop: theme.sizing.scale600,
        }}
      >
        Log in
      </LinkButton>
    </div>
  );
}
